import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import Header from "./Header";
import Collection from "./Collection";
import { Home, About } from "./Routes";

function App() {
  const location = useLocation();

  return (
    <div id="app">
      <main id="content">
        <Header />
        <AnimatePresence exitBeforeEnter>
          <Routes location={location} key={location.key}>
            <Route path="/" element={<Home />}>
              <Route path="info" element={<About />} />
            </Route>
          </Routes>
        </AnimatePresence>
        <Collection />
      </main>
    </div>
  );
}

export default App;
