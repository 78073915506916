import { motion } from "framer-motion";
import { Container, Grid, Col } from "../Grid";
import "./style.scss";

export default function Bio() {
  const bioTransition = {
    in: {
      height: "auto",
      opacity: 1,
      transition: { type: "tween", ease: "easeOut", duration: 0.4 },
    },
    out: {
      height: 0,
      opacity: 0,
      transition: { type: "tween", ease: "easeOut", duration: 0.4 },
    },
  };

  return (
    <motion.section
      className="bio"
      variants={bioTransition}
      initial="out"
      animate="in"
      exit="out"
    >
      <Container>
        <Grid>
          <Col
            xs="10"
            sm="8"
            md="6"
            lg="4"
            mdOffset="3"
            lgOffset="2"
            className="bio-overview"
          >
            <h1 className="bio-heading">Short Bio</h1>
            <div className="bio-content">
              <p>
                I am an incredibly curious designer who has spent 15 years
                looking for ways to create the greatest positive impact I can,
                working with a diverse selection of clients from start-ups to
                established organisations.
              </p>
              <p>
                Currently as Design Director at Liquid, I provide hands-on
                leadership to shape the direction of digital products for a
                variety of clients in government, education and health. I’ve
                been honoured to see my work recognised by institutions such as
                the Webby Awards, One Show and more recently Good Design
                Australia.
              </p>
            </div>
          </Col>
          <Col
            sm="8"
            lg="2"
            mdOffset="3"
            lgOffset="0"
            className="bio-elsewhere"
          >
            <h2 className="bio-heading">Contact</h2>

            <div>
              <a
                href="https://twitter.com/shepthomas"
                rel="noreferrer nofollow"
                target="_blank"
                className="elsewhere-item"
              >
                Twitter
              </a>
            </div>
            <div>
              <a
                href="https://www.linkedin.com/in/mattneal/"
                rel="noreferrer nofollow"
                target="_blank"
                className="elsewhere-item"
              >
                LinkedIn
              </a>
            </div>
            <div className="bio-local">
              <div className="phone">+61 0431 557 678</div>
              <div>
                <a href="mailto: findme@mattneal.com.au">
                  findme@mattneal.com.au
                </a>
              </div>
              <div className="location"> Brisbane, QLD Australia</div>
            </div>
          </Col>
        </Grid>
      </Container>
    </motion.section>
  );
}
