import React from "react";
import { useLocation } from "react-router-dom";
import { Container, Grid, Col } from "../Grid";
import Toggle from "./Toggle.js";
import "./Header.scss";

export default function Header() {
  const location = useLocation();
  const path = location.pathname;
  const isActive = path === "/info" ? true : false;
  const to = path === "/info" ? "/" : "/info";
  return (
    <header className="mn">
      <Container>
        <Grid>
          <Col xs="6" lg="6">
            <h1 className="mn__name">Matt Neal</h1>
            <div className="mn__role">Design Director</div>
            <div className="mn__duration">Liquid, 2021 &mdash; Present</div>
          </Col>
          <Col xs="6">
            <div className="mn__options">
              <Toggle active={isActive} to={to} />
            </div>
          </Col>
        </Grid>
      </Container>
    </header>
  );
}
