import React from "react";
import { Link } from "react-router-dom";

import "./Toggle.scss";

export default function Toggle({ active, to }) {
  return (
    <div className="toggle">
      <Link to={to}>
        <span className={`icon` + (active ? " is-active" : "")}>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13 7L7 13"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M7 7L13 13"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
        <span className="label">About</span>
      </Link>
    </div>
  );
}
