import React from "react";
import Image from "./Image";
import LazyLoad from "react-lazyload";

function CollectionItem({ item, showMouseTooltip, hideMouseTooltip, isDark }) {
  return (
    <div
      className="collection__item"
      onMouseEnter={() => showMouseTooltip(item.project.projectName)}
      onMouseLeave={() => hideMouseTooltip()}
    >
      <figure className="aspect-ratio aspect-ratio--8x5">
        <LazyLoad once>
          {isDark ? (
            <Image
              className="aspect-ratio--object"
              src={item.imageDark.file.url}
              alt={item.project.projectName}
            />
          ) : (
            <Image
              className="aspect-ratio--object"
              src={item.image.file.url}
              alt={item.project.projectName}
            />
          )}
        </LazyLoad>
        <figcaption className="a11y">{item.name}</figcaption>
      </figure>
    </div>
  );
}

export default CollectionItem;
