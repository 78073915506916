import React from "react";
import Bio from "../Bio";

function About() {
  return (
    <>
      <Bio />
    </>
  );
}

export default About;
