import React from "react";
import axios from "axios";
import MouseTooltip from "react-sticky-mouse-tooltip";
import CollectionItem from "./CollectionItem";
import "./Collection.scss";

function shuffle(array) {
  var currentIndex = array.length,
    temporaryValue,
    randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

export default class Collection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hintText: "",
      loading: false,
      isMouseTooltipVisible: false,
      tooltip: "",
      isDark: false,
      items: [],
    };
  }

  searchContentful = async () => {
    const API_BASE_URL = "https://cdn.contentful.com";
    const API_SPACE_ID = "ahqia1jusnkd";
    const API_TOKEN =
      "4816bcc24d110bdfba650068233eb8be6a5c7e51a8d9d93b0072aa17ca5785f5";

    axios
      .get(
        `${API_BASE_URL}/spaces/${API_SPACE_ID}/entries?access_token=${API_TOKEN}&content_type=blockImage&order=sys.createdAt`
      )
      .then((response) => {
        const ASSETS = response.data.includes.Asset;
        const PROJECTS = response.data.includes.Entry;
        const ITEMS = response.data.items;
        let index = [];

        // console.log(ASSETS)
        // console.log(ITEMS)

        ITEMS.forEach((item, i) => {
          let project = PROJECTS.find(
            (project) => project.sys.id === item.fields.project.sys.id
          );
          let image = ASSETS.find(
            (asset) => asset.sys.id === item.fields.image.sys.id
          );
          let imageDark = {};
          if ("imageDark" in item.fields) {
            imageDark = ASSETS.find(
              (asset) => asset.sys.id === item.fields.imageDark.sys.id
            );
          }
          let indexItem = {
            name: item.fields.name,
            project: project.fields,
            image: image.fields,
            imageDark: imageDark.fields,
          };
          index.push(indexItem);
        });

        this.setState((prevState) => ({
          ...prevState,
          items: shuffle(index),
        }));
      });
  };

  componentDidMount() {
    //  Get projects
    this.searchContentful();

    // Initial dark mode check
    const isDark = window.matchMedia("(prefers-color-scheme: dark)");
    this.setState((prevState) => ({ isDark: isDark.matches }));
    //  Detect dark mode change
    isDark.addListener((event: MediaQueryListEvent) => {
      this.setState((prevState) => ({ isDark: event.matches }));
    });
  }

  actions = {
    showMouseTooltip: (name) => {
      this.setState((prevState) => ({
        tooltip: name,
        isMouseTooltipVisible: true,
      }));
    },
    hideMouseTooltip: () => {
      this.setState((prevState) => ({ isMouseTooltipVisible: false }));
    },
  };

  render() {
    const {
      state: { loading, hintText, items, tooltip, isDark },
    } = this;

    return (
      <div className="collection">
        <div className="collection__period">
          <div className="heading">Archived work</div>
          <div className="period">2008 &ndash; 2018</div>
        </div>
        <div className="collection__hint">
          {loading ? "Loading..." : hintText}
        </div>
        <div className="collection__grid">
          {items.length > 0 &&
            items.map((item, index) => {
              return (
                <CollectionItem
                  key={index}
                  item={item}
                  isDark={isDark}
                  {...this.actions}
                />
              );
            })}
        </div>
        <MouseTooltip
          visible={this.state.isMouseTooltipVisible}
          offsetX={15}
          offsetY={10}
          className="collection__tooltip"
        >
          <span>{tooltip}</span>
        </MouseTooltip>
      </div>
    );
  }
}
